/********************************************************
BUTTON  
/********************************************************/

.primary-btn{
    background-image: linear-gradient(to right, #FF8C5A , #FF9458, #FFA655);
    color: $white-color;
    &:hover{
       color: $white-color;
       opacity: 0.8;    
    }
}

.luci-btn{
    border: none;
    border-radius: 25px;
    font-size: $font-size-default;
    padding: 12px 30px;
    min-width: 175px;
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
    &:focus{
        box-shadow: none;
        outline: none;
    }
    &:hover{
        -webkit-transition: all .35s ease;
        transition: all .35s ease;
    }
    &:active{
         box-shadow: none;
        outline: none;
    }
}


/********************************************************
TEXT COLOR
/********************************************************/

.p-text{
    color: $primary-text !important;
}

.s-text{
    color: $secondary-text !important;
}

