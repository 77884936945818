//@charset "UTF-8";
/*--------------------------------------------------------------
# Font
--------------------------------------------------------------*/
@font-face {
  font-family: "Tw Cen MT Std";
  src: url(TwCenMTStd-Bold.eot);
  src: local("Tw Cen MT Std Bold"), local(TwCenMTStd-Bold),
    url(TwCenMTStd-Bold.eot?#iefix) format("embedded-opentype"),
    url(TwCenMTStd-Bold.woff2) format("woff2"),
    url(TwCenMTStd-Bold.woff) format("woff"),
    url(TwCenMTStd-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tw Cen MT Std";
  src: url(TwCenMTStd-ExtraBold.eot);
  src: local("Tw Cen MT Std Extra Bold"), local(TwCenMTStd-ExtraBold),
    url(TwCenMTStd-ExtraBold.eot?#iefix) format("embedded-opentype"),
    url(TwCenMTStd-ExtraBold.woff2) format("woff2"),
    url(TwCenMTStd-ExtraBold.woff) format("woff"),
    url(TwCenMTStd-ExtraBold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tw Cen MT Std";
  src: url(TwCenMTStd-UltraBold.eot);
  src: local("Tw Cen MT Std Ultra Bold"), local(TwCenMTStd-UltraBold),
    url(TwCenMTStd-UltraBold.eot?#iefix) format("embedded-opentype"),
    url(TwCenMTStd-UltraBold.woff2) format("woff2"),
    url(TwCenMTStd-UltraBold.woff) format("woff"),
    url(TwCenMTStd-UltraBold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tw Cen MT Std";
  src: url(TwCenMTStd-Light.eot);
  src: local("Tw Cen MT Std Light"), local(TwCenMTStd-Light),
    url(TwCenMTStd-Light.eot?#iefix) format("embedded-opentype"),
    url(TwCenMTStd-Light.woff2) format("woff2"),
    url(TwCenMTStd-Light.woff) format("woff"),
    url(TwCenMTStd-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tw Cen MT Std";
  src: url(TwCenMTStd-SemiMedium.eot);
  src: local("Tw Cen MT Std Semi Medium"), local(TwCenMTStd-SemiMedium),
    url(TwCenMTStd-SemiMedium.eot?#iefix) format("embedded-opentype"),
    url(TwCenMTStd-SemiMedium.woff2) format("woff2"),
    url(TwCenMTStd-SemiMedium.woff) format("woff"),
    url(TwCenMTStd-SemiMedium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tw Cen MT Std";
  src: url(TwCenMTStd.eot);
  src: local("Tw Cen MT Std"), local(TwCenMTStd),
    url(TwCenMTStd.eot?#iefix) format("embedded-opentype"),
    url(TwCenMTStd.woff2) format("woff2"), url(TwCenMTStd.woff) format("woff"),
    url(TwCenMTStd.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tw Cen Classified MT Std";
  src: url(TwCenClassMTStd-Regular.eot);
  src: local("Tw Cen Classified MT Std Regular"), local(TwCenClassMTStd-Regular),
    url(TwCenClassMTStd-Regular.eot?#iefix) format("embedded-opentype"),
    url(TwCenClassMTStd-Regular.woff2) format("woff2"),
    url(TwCenClassMTStd-Regular.woff) format("woff"),
    url(TwCenClassMTStd-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
body {
  overflow-x: hidden;
}

header {
  min-height: 70px;
  padding: 42px 0 0 0;
  width: 100%;
  top: 0;
  z-index: 11;
  position: absolute;
}

header:before {
  background: linear-gradient(180deg,
      rgba(21, 15, 72, 0.47) 0%,
      rgba(21, 15, 72, 0) 100%);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  content: "";
}

body,
html,
button,
input,
optgroup,
select,
textarea {
  font-family: "Tw Cen MT Std";
  padding: 0;
  margin: 0;
  font-size: 14px;
  text-rendering: optimizeLegibility;
  -moz-font-feature-settings: liga, kern;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

input:focus {
  outline: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.logo img {
  height: 35px;
  margin-right: 62px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  margin-right: 20px;
  transition: all 0.3s;
  line-height: 22px;
  color: #fff;
}

.navbar-nav .nav-item.active .nav-link,
.text-primary {
  color: #fd6d6c !important;
}

.text-secondary {
  color: #99CCFE !important;
}

.navbar-nav .nav-item .nav-link:hover {
  color: #fd6d6c;
  transition: all 0.3s;
}

.navbar-nav .nav-item:last-child .nav-link {
  margin-right: 0;
}

.luci-btn {
  width: 182px;
  height: 50px;
  border-radius: 8px;
  box-shadow: 0 6px 14px 0 rgba(34, 34, 34, 0.1);
  background-color: #fd6d6c;
  color: #000;
  font-weight: 500;
  padding: 9px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.88;
  transition: all 0.3s;
}

.z-11 {
  z-index: 11;
}

.luci-btn:hover {
  opacity: 0.8;
  transition: all 0.3s;
}

.luci-btn-dark {
  width: 254px;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);
  background: linear-gradient(105.5deg, #545ae7 19.57%, #393fcf 78.85%);
  color: #fff !important;
  font-weight: 500;
  font-size: 20px;
  font-weight: 500;
  line-height: 33px;
  transition: all 0.3s;
  border-radius: 30px;
  height: 60px;
  padding: 13px 0;
  margin-top: 30px;
}

.luci-btn-dark:hover {
  opacity: 0.8;
  transition: all 0.3s;
  color: #fff;
}

.banner {
  position: relative;
  padding-top: 120px;
  padding-bottom: 0;
  background: #150f48;
}

section.banner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner:before {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  content: "";
  background: url("../../assets/images/home/background-min.png") no-repeat;
  background-size: cover;
}

//.banner:after {
//  height: 100%;
//  width: 100%;
//  position: absolute;
//  left: 0;
//  right: 0;
//  top: 0;
//  content: "";
//  background-image: url(../../assets/images/main-bg.png);
//  background-repeat: repeat;
//  background-size: contain;
//  -webkit-animation: backgroundScroll 9999999s linear infinite;
//  transition: all 0.35s ease;
//  animation: backgroundScroll 9999999s linear infinite;
//}
.banner p {
  font-size: 32px;
  line-height: 35px;
  color: #ffffff;
  width: 40%;
  margin: 0 auto;
  padding: 15px 0 80px;
}

.banner h1 {
  font-weight: 500;
  padding-top: 20px;
  z-index: 11;
  font-size: 54px;
  line-height: 59px;
  color: #fff;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}
#root {
  scroll-behavior: smooth;
  position: relative;
}

#root:before {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  content: "";
  z-index: 1;
  background: #150f48;
  background-image: url(../../assets/images/bottom-bg-min.png);
  background-repeat: repeat;
  background-size: contain;
  -webkit-animation: backgroundScroll 9999999s linear infinite;
  transition: all 0.35s ease;
  animation: backgroundScroll 9999999s linear infinite;

}

// #root:before {
//   height: 100%;
//   width: 100%;
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   content: "";
//   z-index: 1;
//   background: url(../../assets/images/second-background-min.png) repeat center;
//   //-webkit-animation: backgroundScroll 9999999s linear infinite;
//   //transition: all 1s ease;
//   //animation: backgroundScroll 9999999s linear infinite;
// }



.join-container {
  position: relative;
  z-index: 10;
}

// .join-padding {
//   padding: 100px 0;
// }

.join-background {
  position: relative;
  padding: 200px 0 250px 0;
}

.join-padding-new {
  padding: 100px 0px;
}

.join-background:before {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  content: "";
  background: url("../../assets/images/web-bg-min.png") no-repeat center;
  background-size: 100% 100%;
}

.join-image-box {
  display: flex;
  position: relative;
  z-index: 3;
  justify-content: center;
}

.join-text h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
  width: 40%;
  margin: 0 auto;
  padding-bottom: 40px;
}

.join-text {
  text-align: center;
  width: 100%;
  position: relative;
  z-index: 3;
}

.apSpaceObject01 {
  z-index: 3;
  position: absolute;
  top: 7%;
  left: 0;
}

.apSpaceObject02 {
  z-index: 3;
  position: absolute;
  top: 44%;
  left: 0;
}

.apSpaceObject03 {
  z-index: 3;
  position: absolute;
  top: 65%;
  right: 0;
}

#loading {
  -webkit-animation: rotation 100s infinite linear;
}

.video-frame {
  position: relative;
}

.video-frame iframe {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.left-frame {
  position: absolute;
  width: 138px;
  height: 138px;
  left: 190px;
  top: -15px;
  background: #e5ddff;
  border-radius: 20px;
}

.right-frame {
  position: absolute;
  width: 231px;
  height: 231px;
  right: 190px;
  bottom: -10px;
  background: #96fcbf;
  border-radius: 20px;
}

.app-screen {
  background: linear-gradient(180deg, #0e0a32 0%, #221c59 80.9%);
  padding: 0;
  position: relative;
}

//.informative-area{
//  h2{
//    font-size: 40px;
//    color: #FFFFFF;
//    width: 100%;
//    text-align: left;
//  }
//  .info-text{
//    h4{
//      font-size: 24px;
//      text-align: left;
//      margin: 40px 0 10px 0;
//      color: #FFFFFF;
//    }
//    p{
//      color: #d6d6d6;
//      font-size: 20px;
//      line-height: 25px;
//    }
//    .info-list {
//      span {
//        color: #FFF;
//        font-size: 19px;
//        margin-bottom: 5px;
//      }
//    }
//    .h-2{
//      color: #FFFFFF;
//      font-size: 24px;
//      margin: 20px 0 10px 0;
//    }
//    h3{
//      font-size: 20px;
//      color: #FFFFFF;
//      margin: 20px 0 5px 0;
//    }
//    li{
//      color: #d6d6d6;
//      font-size: 16px;
//      line-height: 22px;
//      margin-bottom: 5px;
//
//      strong{
//        color: #FFF;
//      }
//
//    }
//  }
//}
.informative-area {
  font-family: "Poppins", sans-serif;
  padding-bottom: 80px;

  h2 {
    font-size: 40px;
    color: #FFFFFF;
    font-weight: 700;
    width: 100%;
    text-align: left;
  }

  .info-text {
    h4 {
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      margin: 40px 0 10px 0;
      color: #FFFFFF;
    }

    p {
      font-size: 16px;
      color: #d6d6d6;
      line-height: 25px;
    }

    .info-list {
      span {
        font-weight: 700;
        color: #FFFFFF;
        margin-bottom: 5px;
      }
    }

    .h-2 {
      text-align: left;
      font-size: 22px;
      font-weight: 700;
      color: #FFFFFF;
      margin: 20px 0 10px 0;
    }

    h3 {
      font-size: 20px;
      color: #FFFFFF;
      font-weight: 700;
      margin: 20px 0 5px 0;
    }

    li {

      strong {
        color: #FFF;
      }

      color: #d6d6d6;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 5px;
    }
  }
}

.app-screen h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 49px;
  margin-bottom: 30px;
  color: #ffffff;
}

//.app-screen:after {
//  height: 100%;
//  width: 100%;
//  position: absolute;
//  left: 0;
//  right: 0;
//  top: 0;
//  content: "";
//background-image: url(../../assets/images/secondBackground.svg);
//background-repeat: repeat;
//background-size: contain;
//-webkit-animation: backgroundScroll 9999999s linear infinite;
//transition: all 0.35s ease;
//animation: backgroundScroll 9999999s linear infinite;
//}
.app-screen p {
  font-style: normal;
  font-weight: 100;
  font-size: 24px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.5);
}

.b-main-img img {
  border-radius: 25px;
}
.b-main-img img {
  width: 60% !important;
  margin-bottom: 50px !important;
}
.thumbs-wrapper.axis-vertical {
  display: none;
}
.control-dots .dot {
  width: 15px !important;
  height: 15px !important;
  opacity: 1 !important;
}
.control-dots .dot.selected {
  background: tomato !important;
}
.thumbs.animated {
  display: none;
}
p.carousel-status {
  display: none;
}
.col-lg-6.as-img {
  display: flex;
  justify-content: center;
}

.as-text li {
  list-style-type: circle;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 10px;
}

.as-text ul {
  padding: 25px;
}

.as-text li::marker {
  font-size: 22px;
  color: #fd6d6c;
}

.b-main-img {
  text-align: center;
  position: relative;
}

.as-gap {
  margin-top: 100px;
}

.app-screen-2 {
  padding-bottom: 180px;
}

.app-screen-2 .col-lg-6 {
  z-index: 11;
}

.details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

img.earth-app {
  position: absolute;
  left: 150px;
  top: -50px;
  z-index: -1;
  -webkit-animation: rotation 100s infinite linear;
}

img.earth-app-2 {
  position: absolute;
  right: -50px;
  top: -50px;
  z-index: -1;
  -webkit-animation: rotation 100s infinite linear;
}

img.earth-app-3 {
  position: absolute;
  left: 140px;
  top: -70px;
  z-index: -1;
  -webkit-animation: rotation 100s infinite linear;
}

.minerals-app {
  position: absolute;
  right: -62px;
  bottom: -62px;
}

.minerals-app-2 {
  position: absolute;
  left: 160px;
  bottom: -45px;
}

/*--------------------------------------------------------------
# Animation
--------------------------------------------------------------*/
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-webkit-keyframes backgroundScroll {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -99999999px 0;
  }
}

@keyframes backgroundScroll {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -99999999px 0;
  }
}

.astronaut {
  animation-name: bounce-1;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-6px);
  }

  100% {
    transform: translateY(0);
  }
}

.shape3 {
  position: absolute;
  left: 5%;
  top: 15%;
  -webkit-animation: animationFramesOne 100s infinite linear;
  animation: animationFramesOne 100s infinite linear;
}

.shape4 {
  position: absolute;
  right: 35%;
  top: 10%;
  -webkit-animation: animationFramesOne 50s infinite linear;
  animation: animationFramesOne 50s infinite linear;
}

.shape5 {
  position: absolute;
  right: 10%;
  top: 45%;
  -webkit-animation: animationFramesOne 60s infinite linear;
  animation: animationFramesOne 60s infinite linear;
}

.shape7 {
  position: absolute;
  right: 10%;
  top: 47%;
}

.shape6 {
  position: absolute;
  left: 15%;
  top: 60%;
  -webkit-animation: animationFramesOne 100s infinite linear;
  animation: animationFramesOne 100s infinite linear;
}

.spaceObject01 {
  position: absolute;
  z-index: 3;
  left: 2.5%;
  top: 15%;
}

.spaceObject02 {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 50%;
}

.spaceObject03 {
  position: absolute;
  z-index: 3;
  left: 5%;
  top: 25%;
}

.spaceObject04 {
  position: absolute;
  z-index: 3;
  left: 27%;
  top: 60%;
}

.spaceObject05 {
  position: absolute;
  z-index: 3;
  left: 30%;
  top: 27%;
}

.spaceObject07 {
  position: absolute;
  z-index: 3;
  right: 20%;
  top: 20%;
}

.spaceObject07 img {
  width: 90%;
}

.spaceObject08 {
  position: absolute;
  z-index: 3;
  right: 14%;
  top: 39%;

  img {
    width: 63%;
  }
}


.spaceObject09 {
  position: absolute;
  z-index: 3;
  left: 78%;
  top: 27%;
  transform: rotate(-13deg);

  img {
    width: 95%;
  }
}

@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes circle-d {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.circle-d {
  -webkit-animation: rotating 40s linear infinite;
  -moz-animation: rotating 40s linear infinite;
  -ms-animation: rotating 40s linear infinite;
  -o-animation: rotating 40s linear infinite;
  animation: rotating 40s linear infinite;
}

/*--------------------------------------------------------------
# Slider
--------------------------------------------------------------*/
.app-shot-one .container-fluid {
  max-width: 1595px;
  width: 100%;
  position: relative;
}

.app-shot-one .app-shot-one__carousel {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -20px;
}

.app-shot-one .app-shot-one__carousel:before {
  overflow: hidden;
  z-index: 1;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-image: url(../../assets/images/app-shots/app-screen-frame.png);
  background-position: center center;
  background-repeat: no-repeat;
  width: 305px;
  height: 651px;
  background-size: 100% auto;
  box-shadow: 0 0 60px 0 rgba(5, 5, 6, 0.05);
}

@media (max-width: 1440px) {
  .app-shot-one .app-shot-one__carousel:before {
    -webkit-transform: translate(-50%, -49%) scaleY(0.95);
    transform: translate(-50%, -49%) scaleY(0.95);
  }
}

@media (max-width: 1280px) {
  .app-shot-one .app-shot-one__carousel:before {
    -webkit-transform: translate(-50%, -49%) scale(0.8);
    transform: translate(-50%, -49%) scale(0.8);
  }
}

.app-shot-one .app-shot-one__carousel .owl-dots {
  position: absolute;
  bottom: -60px;
  left: 0;
  text-align: center;
  z-index: 10;
  width: 100%;
}

.app-shot-one .owl-item img {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

@media (max-width: 991px) {
  .app-shot-one .app-shot-one__carousel::before {
    display: none;
  }

  .app-shot-one .app-shot-one__carousel {
    padding: 0;
    margin-top: 0;
    padding-bottom: 15px;
  }
}

.app-shot-one {
  background: #1C1C44;
  padding: 0 0 80px 0;
  position: relative;
  z-index: 9;
  height: 100%;
  margin-top: -190px;
  padding-top: 100px;
}

.app-shot-one .as-title {
  position: relative;
  z-index: 3;
  font-size: 54px;
  line-height: 59px;
  text-align: center;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 130px;
}

.owl-carousel .owl-item img {
  border-radius: 20px;
  width: auto !important;
  margin: 0 auto;
}

.app-shot-one:before {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: linear-gradient(356deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 0, 0, 1) 3%,
      rgba(0, 212, 255, 0) 100%);
  display: none;
}

/*--------------------------------------------------------------
# Form
--------------------------------------------------------------*/
.beta-test-dot {
  background: #201a56;
  border-radius: 20px;
  margin: 150px 0;
  z-index: 11;
}

.beta-test-dot .join-text {
  margin: 60px 0;
}

.email-form {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.email-form .input-email {
  max-width: 400px;
  min-width: 400px;
  height: 60px;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #fff;
  font-size: 20px;
  color: #fff;
  padding: 8px 15px;
  background: #2c1c58;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

/*--------------------------------------------------------------
# FAQ Page
--------------------------------------------------------------*/
.w-60 {
  width: 60% !important;
}

.w-100 {
  width: 100% !important;
}


.inner-area {
  z-index: 11;
  min-height: 900px;
}

.inner-area h2 {
  text-align: center;
  width: 100%;
  margin-top: 80px;
}

.b-inner:after {
  content: "";
  background-image: url(../../assets/images/main-bg-faq.png);
  height: 50%;
}

.f-app-screen {
  z-index: 1;
}

.b-inner {
  overflow: hidden;
}

section.banner.b-inner:before {
  height: 0%;
}

.f-app-screen:after {
  top: 70px;
  content: "";
  background-image: url(../../assets/images/bg-middle-2.png);
  height: 70%;
}

.app-shot-one-f:before {
  display: none;
}

.app-shot-one-f {
  background: linear-gradient(#150f47, #09081a);
  margin-top: 0;
  padding-top: 0;
}

section.banner.b-inner .shape6 {
  left: 5%;
  top: 30%;
}

.faq-area .card-header {
  padding: 0 0 7px;
  border: none;
  box-shadow: none;
  border-radius: 30px;
}

.faq-area {
  padding-bottom: 130px;
}

.faq-area .card-header .btn-link {
  position: relative;
  width: 100%;
  text-align: left;
  color: #ffffff;
  padding: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  padding-right: 30px;
}

.faq-area .card-header .btn-link:hover,
.faq-area .card-header .btn-link.focus,
.faq-area .card-header .btn-link:focus {
  text-decoration: none;
  box-shadow: none;
}

.faq-area .card-header .btn-link .arrow-toggle {
  position: absolute;
  left: auto;
  right: 0;
  top: 13px;
  filter: invert(1);
}

.faq-area .card-header .btn-link[aria-expanded="true"] .arrow-toggle {
  transform: rotate(0deg);
}

.faq-area .card-header .btn-link[aria-expanded="false"] .arrow-toggle {
  transform: rotate(180deg);
}

.faq-area .card-body {
  padding: 0;
  font-size: 19px;
  line-height: 17px;
  background: transparent;
  color: #d6d6d6;
  padding-right: 30px;
}


.faq-area .card {
  border: none;
  border-bottom: none;
  border-radius: 30px !important;
  margin-bottom: 20px;
  background-color: transparent;
  padding: 20px 20px 15px;
  background: rgba(92, 84, 158, 0.1);
  border: 1px solid #5c549e !important;
}

@media (max-width: 991px) {
  .w-60 {
    width: 90% !important;
  }

  .b-inner p {
    padding: 15px 0 0;
  }

  .b-inner .shape6 {
    display: none;
  }

  .app-shot-one-f .beta-test-dot {
    margin: 0 15px 50px;
  }

  section.banner.b-inner h1 {
    padding-top: 80px;
  }
}

a {
  word-wrap: break-word;
}

.support-h1 {
  padding-top: 0px !important;
}

/*--------------------------------------------------------------
# About Page
--------------------------------------------------------------*/
img.w-120.br-50 {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin-top: -100px;
}

.banner-text {
  background: #0000007a;
  border-radius: 50px;
  padding: 20px;
  margin-top: 130px;
}

.app-screen p.a-txt {
  font-size: 18px;
  line-height: 17px;
  text-align: center;
  color: #d6d6d6;
}

.a-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  margin-top: 20px;
  color: #ffffff;
}

.a-field {
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 20px;
  color: #d6d6d6;
}

.a-txt b {
  display: block;
  margin-top: 10px;
}

.a-btm {
  padding-bottom: 180px;
}

.our-product h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 49px;
  margin-bottom: 30px;
  color: #ffffff;
}

.our-product h3 {
  font-size: 32px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 25px;
  color: #ffffff;
}

.join-product {
  text-align: center;
  width: 100%;
  background: rgba(14, 14, 14, 0.5);
  border-radius: 30px;
  margin: 150px 0;
  padding: 50px 0;
  z-index: 11;
  height: 375px;
}

.our-product {
  margin-bottom: 80px;
}

img.app-product {
  border-radius: 55px;
  max-width: 730px;
}

.lse {
  display: block;
  margin: 0 auto;
  margin-top: -30px;
  width: 424px;
  height: 60px;
  z-index: 1;
  padding: 13px 0;
  position: relative;
  background: radial-gradient(54.31% 195.82% at 47.79% 54.31%,
      #13165f 0%,
      #000000 100%);
  border-radius: 60px;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
  transition: all 0.35s ease;
}

.lse:hover {
  opacity: 1;
  transition: all 0.35s ease;
  margin-top: -35px;
  color: #ffffff;
}

@media (max-width: 991px) {
  img.app-product {
    width: 90%;
    border-radius: 10px;
  }

  .lse {
    width: 60%;
    margin-top: -30px;
  }

  .our-product h3 {
    margin: 0 10% 20px;
  }
}

@media (max-width: 480px) {
  .lse {
    width: 90%;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  padding: 70px 0;
}

.footer-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.footer ul li {
  list-style-type: none;
  padding: 0;
}

.footer label {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.footer-text {
  z-index: 11;
}

.app-shot-one:after {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  content: "";
  background-image: url(../../assets/images/bottom-bg-min.png);
  background-repeat: repeat;
  background-size: contain;
  -webkit-animation: backgroundScroll 9999999s linear infinite;
  transition: all 0.35s ease;
  animation: backgroundScroll 9999999s linear infinite;
}

.footer ul li a {
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  font-size: 20px;
  line-height: 44px;
  color: rgba(255, 255, 255, 0.5);
}

.footer ul li a:hover {
  color: #fd6d6c;
  text-decoration: none;
}

.footer-logo {
  display: flex;
  flex-direction: column;
}

.footer-logo span {
  display: block;
  font-size: 20px;
  line-height: 28px;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.5);
}

.custom-shape-divider-top {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 61px;
  // background: #140F3D;
}

.custom-shape-divider-top-about svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 61px;
  background: #140F3D;
}

.custom-shape-divider-top .shape-fill {
  fill: #150f48;
}

.custom-shape-divider-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
  // background: #140F3D;
}

.custom-shape-divider-bottom .shape-fill {
  fill: #150f47;
}

.web-hide {
  display: none;
}

.imageCarousel .owl-dots {
  display: flex;
  width: fit-content;
  margin: 20px auto 0;
  gap: 8px;
}

.imageCarousel .owl-dots .owl-dot {
  display: flex;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #fff;
}

.imageCarousel .owl-dots .owl-dot.active {
  background: #fd6d6c;
}

/*--------------------------------------------------------------
# Max-Width 1199PX
--------------------------------------------------------------*/
@media (max-width: 1199px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    margin-right: 10px;
  }

  .luci-btn-dark {
    margin-left: 0;
  }

  .banner h1 {
    padding-top: 0;
  }

  .b-main-img img {
    margin-right: 0;
  }

  .join-text h2 {
    width: 60%;
  }

  img.earth-app {
    left: 65px;
  }

  img.earth-app-3 {
    left: 35px;
  }

  .minerals-app-2 {
    left: 60px;
  }
}

/*--------------------------------------------------------------
# Min & Max Width 991PX
--------------------------------------------------------------*/
@media (min-width: 991px) {
  .as-img {
    padding-right: 50px;
  }
}

@media (max-width: 991px) {
  .banner {
    padding: 60px 0 30px;
    overflow: hidden;
  }

  .email-input {
    width: 100%;
  }

  .validation {
    margin-left: 36px !important;
  }

  .success {
    margin-left: 50px !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    margin-right: 0;
    color: #000;
  }

  .banner h1 {
    padding-top: 50px;
  }

  .luci-btn-dark {
    margin-left: 0;
    margin-top: 0;
  }

  .luci-btn {
    width: 150px;
  }

  .b-main-img {
    text-align: center;
  }

  .col-lg-6.as-img {
    display: block;
  }

  .b-main-img img {
    margin-right: 0;
    width: 50% !important;
    margin-bottom: 50px !important;
  }

  .email-form {
    flex-direction: column;
    align-items: center;
  }

  .email-form .luci-btn-dark {
    margin-top: 15px;
    width: 50%;
    margin-left: 0;
  }

  .footer ul li a {
    margin-right: 20px;
  }

  .navbar-light .navbar-toggler {
    background: #fff0;
  }

  .shape3,
  .shape5,
  .shape4,
  .shape7 {
    display: none;
  }

  .video-frame iframe {
    width: 100%;
  }

  header {
    z-index: 111;
  }

  ul.navbar-nav {
    background: black;
    border-radius: 4px;
  }

  .banner p,
  .join-text h2 {
    width: 95%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .left-frame,
  .right-frame {
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .shape7 img {
    width: 40px;
    height: 100%;
  }

  .shape7 {
    top: 35%;
  }

  .apSpaceObject01,
  .apSpaceObject02,
  .apSpaceObject03 {
    display: none;
  }

  img.earth-app,
  img.earth-app-2,
  img.earth-app-3 {
    position: absolute;
    left: 30px;
    top: -40px;
    z-index: -1;
    -webkit-animation: rotation 100s infinite linear;
    width: 30% !important;
  }

  img.minerals-app,
  img.minerals-app-2 {
    width: 25%;
    right: 35px;
    bottom: -35px;
    left: auto;
  }

  .web-hide {
    display: block;
  }

  .mob-hide {
    display: none;
  }

  .app-screen h2 {
    margin-bottom: 40px;
    text-align: center;
  }

  .email-form .input-email {
    max-width: initial;
    min-width: auto;
    width: 80%;
  }

  .code-optional {
    margin-left: 0px !important;
    margin-top: 25px;
  }
}

@media (min-width: 768px)and (max-width: 991px) {
  .validation {
    margin-left: 90px !important;
  }

  .success {
    margin-left: 100px !important;
  }
}

/*--------------------------------------------------------------
# Max-Width 767PX
--------------------------------------------------------------*/
@media (max-width: 767px) {
  .footer-item {
    flex-direction: column;
    align-items: center;
  }

  .email-form .input-email {
    min-width: 90% !important;
  }

  .code-optional {
    margin-left: 0px !important;
    margin-top: 25px;
  }

  .luci-btn-dark {
    width: 90%;
  }

  .footer ul {
    flex-direction: column;
    text-align: center;
  }

  .footer ul li a {
    margin-left: 0;
    margin-right: 0;
    line-height: 30px;
  }

  .email-form .luci-btn-dark {
    width: 90%;
  }

  .app-screen-2 {
    padding-bottom: 100px;
  }

  .owl-carousel .owl-item img {
    width: auto !important;
    margin: 0 auto;
  }

  .beta-test-dot {
    margin: 50px 15px;
  }

  .footer-link {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .spaceObject01,
  .spaceObject02,
  .spaceObject03,
  .spaceObject04,
  .spaceObject05,
  .spaceObject07,
  .spaceObject08,
  .spaceObject09,
  .apSpaceObject01,
  .apSpaceObject02,
  .apSpaceObject03 {
    display: none;
  }
}

@media (max-width: 414px) {
  .b-main-img img {
    width: 80% !important;
  }
}
.position-relative {
  position: relative;
}

.earth-img-d-new {
  position: absolute;
  right: -150px;
}

.earth-img-new {
  position: absolute;
  left: 0;
  top: -50px;
  z-index: 9;
}

.about-banner {
  z-index: 9;
}

section.about-banner:before {
  height: 100% !important;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  content: "";
  background: none; //url('../../assets/images/top-support-star-min.png') no-repeat;
  background-position: center center;
}

section.app-screen-about {
  background: none;
}

.footer-bottom {
  z-index: 9;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 25px;
}

.footer-bottom-text {
  color: rgba(255, 255, 255, 0.5);
  font-size: 20px;
  font-weight: 300;
  margin-left: 15px;
  margin-right: 15px;
}

.astroid-a {
  position: absolute;
  right: 0px;
  z-index: 9;
  top: 0;
}

.astroid-b {
  position: absolute;
  right: 0px;
  z-index: 9;
  top: 0;
  left: auto;
}


.curve-top img,
.curve-bottom img {
  width: 100%;
}

.join-image-box img {
  @media (max-width: 1300px) {
    height: 300px;
    width: 100%;
  }

  @media (max-width:1100px) {
    height: 200px;
  }

  @media (max-width:800px) {
    height: 150px;
  }
}

.curve-top,
.curve-bottom {
  display: none;
}

.home-page .curve-top,
.home-page .curve-bottom {
  display: block;
}


.support-a {
  padding-top: 150px;
}

.support-bg {
  position: relative;
  padding: 100px 0;
  background: #140F3D;
}

.support-bg:before {
  height: 100% !important;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  content: "";
  background: none; // url('../../assets/images/support-middle-min.png') no-repeat;
  background-position: center center;
  background-size: 100%;
}

.bg-btm-highlight {
  z-index: 9;
}

.earth-support {
  top: 150px;
}

.about-padding {
  padding-bottom: 150px;
}


.mt-support {
  margin-top: 50px;
}

.sb-light,
.ssb-light svg {
  background: #221C59;
}

.rocket-main {
  width: 300px;
  margin-top: 130px;
  // margin-top: calc(100vh - 50%);
}

// .cloud-title {
//   position: relative;
// }

// .banner-bg:before {
//   height: 100% !important;
//   width: 100%;
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   z-index: 2;
//   content: "";
//   background: url('../../assets/images/home/title-bg.png') no-repeat;
//   background-position: center center;
//   background-size: 100%;
// }
.validation {
  display: flex;
  margin-left: 10px;
  color: red;
  text-align: center;
  margin-top: 18px;
  font-size: 18px;
  margin-left: 18px;
}

.success {
  display: flex;
  margin-left: 10px;
  color: #4CAF50;
  text-align: center;
  margin-top: 18px;
  font-size: 20px;
  margin-left: 18px;
}

.code-optional {
  margin-left: 70px;
}

.doggy {
  width: 155px;
  position: absolute;
  margin-top: 68px;
  margin-left: 30px;
  // margin-top: calc(100vh - 55%);
}

@media (max-width: 768px) {
  .rocket-main {
    width: 80%;
    margin-left: -40px;
  }

  .email-input {
    width: 100%;
  }

  .doggy {
    width: 35%;
    position: absolute;
    margin-top: 70px;
    margin-left: 30px;
  }

  .join-background:before {
    background-size: cover;
  }

  .earth-img-d-new {
    right: 0;
  }

  .cloud-title {
    font-size: 40px !important;
    line-height: 40px !important;
  }
}

@media (max-width: 450px) {
  .rocket-main {
    width: 100%;
    margin-left: -40px;
  }
  .doggy {
    width: 43%;
    position: absolute;
    margin-top: 63px;
    margin-left: 37px
  }
  .as-text ul {
    padding-top: 0 !important;
  }
}
// @media (min-width:768px) and (max-width: 868px) {
//   .email-form .input-email {
//     min-width: 45%;
//   }
// }
