$fs_11: 11px;
$fs_12: 12px;
$fs_13: 13px;
$fs_14: 14px;
$fs_15: 15px;
$fs_17: 17px;
$fs_18: 18px;
$fs_21: 21px;
$fs_22: 22px;
$fs_24: 24px;
$fs_28: 28px;
$fs_32: 32px;
$fs_34: 34px;
$fs_42: 42px;

$fw_500: 500;
$fw_600: 600;
$fw_900: 900;
$fw_bold: bold;
$fw_normal: normal;

$m_5: 5px;
$m_8: 8px;
$m_10: 10px;
$m_12: 12px;
$m_15: 15px;
$m_16: 16px;
$m_17: 17px;
$m_18: 18px;
$m_20: 20px;
$m_23: 23px;
$m_25: 25px;
$m_30: 30px;
$m_34: 34px;
$m_35: 35px;
$m_40: 40px;
$m_45: 45px;
$m_50: 50px;
$m_55: 55px;
$m_60: 60px;
$m_70: 70px;
$m_90: 90px;
$m_95: 95px;
$m_100: 100px;
$m_120: 120px;

$g_7: 56px;
$g_8: 64px;
$g_9: 72px;
