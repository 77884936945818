/********************************************************
Header SCSS
/********************************************************/

header{
    .navbar-light {
        z-index: 1;
        padding-right: 0;
        .navbar-nav .nav-link{
            color: $white-color;
            padding-right: 0.7rem;
            padding-left: 0.7rem;
            &:hover{
                color: $white-color;
                text-decoration: none;
                opacity: 0.9;
            }
             &:focus, &:active{
                color: $white-color;
            }
        }
        .dropdown-menu{
          background-color: #FFF0;
          position: unset;
          border: none;
        }
        .navbar-collapse{
             .navbar-nav .nav-link{
               &:hover{
                color: $white-color;
            }
                 &:focus, &:active{
                    color: $white-color;
                }
            }
        }
    }
}

/********************************************************/
/* Max-Width 991PX     **********************************/
/********************************************************/
@media (max-width: 991px) {
    header{
       .bg-header{
           .navbar-collapse{
               position: absolute;
                width: 60%;
                right: 7px;
                top: 60px;
             .navbar-nav .nav-link{
               &:hover{
                color: $primary-text;
            }
                 &:focus, &:active{
                        color: $primary-text;
                    }
                }
            }
            .navbar-light{
                padding: 10px 0 0 10px !important;
                z-index: 2;
            }
            .navbar-toggler{
                padding: 0;
                border: none;
                margin-top: 5px;
            }
            .btn-signup{
                position: absolute;
                left: auto;
                right: 70px;
                top: 10px;
                .luci-btn{
                    padding: 10px 20px;
                    min-width: 130px;
                }
            }
            .navbar-nav{
                background: $white-color;
                border-radius: 5px;
                -webkit-box-shadow: 0 2px 48px 0 rgba(0,0,0,0.08);
                box-shadow: 0 2px 48px 0 rgba(0,0,0,0.08);
                .nav-link{
                    color: $primary-text;
                }
            }
        }
    }
}
